import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

import { getAccessToken } from './functions/auth';

async function onRequest(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
  const accessToken = await getAccessToken();

  config.headers = {
    Authorization: `Bearer ${accessToken}`,
    'content-type': 'application/json',
    tenant_id: localStorage.getItem('tenantId'),
  };

  return config;
}

async function onResponseError(error: AxiosError): Promise<never> {
  return Promise.reject(error);
}

function httpClient(): AxiosInstance {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_URL,
  };

  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use(onRequest);
  instance.interceptors.response.use(undefined, onResponseError);

  return instance;
}

export default httpClient();
